@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply font-mothermeSans not-italic;
    font-optical-sizing: auto;
  }

  body {
    @apply flex flex-col min-h-screen text-motherme-400 dark:text-white bg-white dark:bg-motherme-400 overflow-x-hidden;
    background-image: url('/static/images/bg-pattern.jpg');
    background-repeat: repeat;
    background-size: auto;
    background-position: top left;
  }

  a, button, input, select {
    &:focus-visible {
      @apply outline-motherme-300 outline-[3px] outline-offset-4;
    }
  }

  h1, h2, h3, h4, h5 {
    @apply font-mothermeSerif;
  }

  h2 {
    @apply text-2xl md:text-3xl;
  }

  form h2 {
    @apply leading-loose text-xl;
  }
}

@layer components {
  .site-padding {
    @apply px-6 sm:px-10 md:px-28 lg:px-40;
  }

  .site-container {
    @apply max-w-[1512px] mx-auto;
  }

  .rich-text {
    @apply text-lg md:text-xl;

    h3 {
      @apply text-2xl md:text-3xl leading-[150%] pb-2;
    }

    h4 {
      @apply md:text-2xl leading-[150%] pt-7 mb-4 md:mb-8;
    }

    p {
      @apply leading-10 mb-8 md:mb-10 last:mb-0;
    }

    a {
      @apply underline underline-offset-8 decoration-[1.5px] decoration-motherme-200 hover:decoration-motherme-300;
    }

    ol {
      @apply list-decimal list-outside ml-5;

      li {
        &:last-child {
          @apply mb-5;
        }
      }
    }

    ul {
      @apply list-disc list-outside ml-5;

      li {
        @apply leading-10;

        &:last-child {
          @apply mb-5;
        }
      }
    }
  }

  #footer {
    .rich-text {
      @apply leading-4 mb-8 md:mb-10 last:mb-0;
      @apply text-base;
    }
  }

  .button-menu-toggle {
    position: relative;
    display: block;
    width: 25px;
    height: 50px;
    background: transparent;
    border: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-out;
    margin: auto;

    &__line {
      @apply bg-motherme-400 dark:bg-white;
      position: absolute;
      display: block;
      width: 100%;
      height: 2px;
      transform: rotate(0deg);
      transition: top, transform, color, 0.25s ease-out;

      &:nth-child(1) {
        top: 2px;

        .mobile-menu-is-open & {
          @apply bg-white;
          transform: rotate(45deg);
        }
      }

      &:nth-child(2) {
        .mobile-menu-is-open & {
          @apply bg-white;
          top: 2px;
          transform: rotate(-45deg);
        }
      }

      &:nth-child(3) {
        top: 13px;

        .mobile-menu-is-open & {
          display: none;
        }
      }
    }
  }

  .header-logo {
    transition: color, 0.25s ease-out;

    &.mobile-menu-is-open {
      @apply text-white;
    }
  }

}

@layer utilities {
  div, ul, li {
    // border: 1px solid #f00;
  }
}
